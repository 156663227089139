import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./footer.css";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <Navbar className="mt-3 footer">
      <Container className="justify-content-center">
        &copy; cADz {year}
      </Container>
    </Navbar>
  );
}

export default Footer;
