import { Card } from "react-bootstrap";
import Variables from "../../Utils/Variables";
import "./Cards.css";

function TotalCostCard({ totalCost }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={totalCost}>
        <Card.Header className="card-header">Total Cost</Card.Header>
        <Card.Body>
          <Card.Text>
          <div className="card-txt">
              ${totalCost.toLocaleString("en-us", Variables.NUMBER_OPTIONS)}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TotalCostCard;
