import { Link } from "react-router-dom";
import "../../App.css";

function FillupVehicleButtons({ setVehicleId }) {
  return (
    <div className="justify-content-end">
      <Link to="/fillup" className="">
        <button className="btn-custom ">Fill-up</button>
      </Link>{" "}
      <Link to="/addVehicle">
        <button className="btn-custom ">Vehicles</button>
      </Link>
    </div>
  );
}

export default FillupVehicleButtons;
