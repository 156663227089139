import "./spinner.css";

function LoadingInApp() {
  return (
    <div className="pb-3 pt-2 text-center">
      Loading...
      <div className="spinner-border text-dark " role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingInApp;
