import { Card } from "react-bootstrap";

function TotalFillupsLogged({ totalFillupsLogged }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2 custom-card-background" key={totalFillupsLogged}>
        <Card.Body>
          <Card.Text>
            <div className="stats-txt ">
              {parseInt(totalFillupsLogged.toFixed(0)).toLocaleString()}{" "}
              fill-ups logged
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TotalFillupsLogged;
