import { Card } from "react-bootstrap";
import "./Cards.css";

function MpgCard({ mpg }) {
  return (
    <>
      {mpg > 0 && (
        <div className="col mx-auto">
          <Card className="mb-2" key={mpg}>
            <Card.Header className="card-header">MPG Avg</Card.Header>
            <Card.Body>
              <Card.Text>
              <div className="card-txt">{mpg.toFixed(2)}</div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}

export default MpgCard;
