import { Card } from "react-bootstrap";
import Variables from "../../Utils/Variables";

function MilesLast30Days({ milesLast30Days }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={milesLast30Days}>
        <Card.Header className="card-header">Miles last 30 days</Card.Header>
        <Card.Body>
          <Card.Text>
            <div className="card-txt">{milesLast30Days}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MilesLast30Days;
