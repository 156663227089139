import axios from "axios";
const URL = process.env.REACT_APP_API_SERVER_URL;

// Get the specified number of fillups for a user AND vehicle
export const getStatTotals = async () => {
  const res = await axios.get(`${URL}api/stats/totals/`, {
    timeout: 50000, // Set a timeout of 50 seconds
  });
  return res.data;
};
