import { Card } from "react-bootstrap";
import "./Cards.css";

function FillupCountCard({ count }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={count}>
        <Card.Header className="card-header">Fillup Count</Card.Header>
        <Card.Body>
          <Card.Text>
            <div className="card-txt">{count}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FillupCountCard;
