import axios from "axios";
import { useQuery } from "@tanstack/react-query";

/**
 * Calls the server every 10 minutes to try to keep it alive
 * @returns
 */
function KeepAlive(token) {
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_API_SERVER_URL}wakeup`,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  const getUp = useQuery({
    queryKey: ["wakeup", options],
    queryFn: async (token) => {
      const res = await axios.request(options);
      console.log(res.data);
      return res.data;
    },
    refetchInterval: 6000000,
  });
  return getUp;
}

export default KeepAlive;
