import AppContext from "../auth/AppContext";
import { useContext } from "react";
import useBulkFillup from "../hooks/useBulkFillup";
import { Container } from "react-bootstrap";

function Admin() {
  const { groups } = useContext(AppContext);

  const { token } = useContext(AppContext);

  const { bulkFillup } = useBulkFillup();
  const handleImport = () => {
    bulkFillup(token);
  };

  return (
    <>
      <Container>
        <h3>Admin Page</h3>
        Groups:{" "}
        {groups.map((group) => {
          return `${group.name} `;
        })}
      </Container>
    </>
  );
}

export default Admin;
