import axios from "axios";
const URL = process.env.REACT_APP_API_SERVER_URL

// Get list of years
export const getYears = async (token) => {
  const res = await axios.get(
    `${URL}api/year`,
    {
      timeout: 100000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// Get all makes for a specific year
export const getMakes = async (year, token) => {
  const res = await axios.get(
    `${URL}api/makes/${year}`,
    {
      timeout: 100000, // Set a timeout of 10 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// Get models for a yer and make
export const getModels = async (year, make, token) => {
  const res = await axios.get(
    `${URL}api/models/${year}/${make}`,
    {
      timeout: 100000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};
