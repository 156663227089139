import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Variables from "../Utils/Variables";

const URL = process.env.REACT_APP_API_SERVER_URL;

export const useCreateTrip = (token) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (trip) => {
      console.log(trip);
      const response = await axios.post(`${URL}api/trips/createTrip`, trip, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Variables.TRIPS]);
      },
    }
  );
};

export const useGetListOfTrips = (token, userId) => {
  return useQuery({
    queryKey: [Variables.TRIPS, token, userId],
    queryFn: async () => {
      const response = await axios.get(`${URL}api/trips/user/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    enabled: !!token && !!userId,
  });
};

export const useGetTripDetails = (token, tripId) => {
  return useQuery({
    queryKey: [Variables.TRIP_DETAILS, token, tripId],
    queryFn: async () => {
      const response = await axios.get(`${URL}api/trips/${tripId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    enabled: !!token && !!tripId,
  });
};

export const useUpdateTrip = (token) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (tripId, fillupId) => {
      const response = await axios.put(
        `${URL}api/trips/update/${tripId}`,
        { fillupId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Variables.TRIP_DETAILS]);
      },
    }
  );
};

export const useDeleteTrip = (token) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (tripId) => {
      const response = await axios.delete(`${URL}api/trips/delete/${tripId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Variables.TRIPS]);
      },
    }
  );
};
