import { Form } from "react-bootstrap";

const TripsDropDown = ({ trips, handleTripSelection }) => {
  return (
    <Form.Group controlId="formDropDown">
      <Form.Select
        style={{ maxWidth: "500px" }}
        aria-label="Trips"
        required
        onChange={handleTripSelection}
      >
        <option>Select Trip</option>
        {trips?.map((trip) => {
          return (
            <option key={trip._id} value={trip._id}>
              {trip.desc}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default TripsDropDown;
