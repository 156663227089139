import CanvasJSReact from "@canvasjs/react-charts";
import HorizontalLine from "../LookAndFeel/HorizontalLine";
import FillupOffCanvas from "./FillupOffCanvas";
import { useState } from "react";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function MpgChart({ fillups, setShowFillupDetails, showFillupDetails }) {
  const [rowData, setRowData] = useState();

  // On clicking the chart, we fire off the offcanvas view of details
  const onClick = (e) => {
    setRowData(e.dataPoint.fillup);
    setShowFillupDetails(true);
  };

  const { mpg, cpg, cost} = buildDataPoints(fillups);
  const options = {
    animationEnabled: true,
    height: 250,
    axisY: {},
    axisX: {
      interval: 1,
    },
    data: [
      {
        name: "MPG",
        type: "spline",
        indexLabel: "{y}",
        click: onClick,
        showInLegend: true,
        dataPoints: mpg,
      },
      {
        name: "CPG",
        yValueFormatString: "$##.#0",
        type: "spline",
        indexLabel: "{y}",
        showInLegend: true,
        click: onClick,
        dataPoints: cpg,
      },
      {
        name: "Cost",
        yValueFormatString: "$##.#0",
        type: "spline",
        indexLabel: "{y}",
        showInLegend: true,
        click: onClick,
        dataPoints: cost,
      },
    ],
  };

  return (
    <>
      <HorizontalLine />
      <h4 className="text-center">MPG / CPG / Cost - Last 10 fillups</h4>
      <div>{mpg?.length > 0 && <CanvasJSChart options={options} />}</div>
      {showFillupDetails && (
        <FillupOffCanvas
          showFillupDetails={showFillupDetails}
          rowData={rowData}
          setShowFillupDetails={setShowFillupDetails}
        />
      )}
    </>
  );
}

export default MpgChart;

// Put together all the data for the chart
function buildDataPoints(fillups) {
  let mpgPoints = [];
  let costPerGallonPoints = [];
  let fillupCost = [];
  let fillupCount = fillups.length;

  // Only show a max of the last 30 fill ups
  if (fillups.length > 10) {
    fillupCount = 10;
  }

  for (let i = 0; i < fillupCount; i++) {
    let dataObj = fillups[i];
    let mpgPoint = {
      x: fillupCount - i,
      y: dataObj.tripMpg,
      fillup: dataObj,
    };

    let cpgPoint = {
      x: fillupCount - i,
      y: parseFloat(dataObj.costPerGallon.toFixed(2)),
      fillup: dataObj,
    };

    let fillupPoint = {
      x: fillupCount - i,
      y: dataObj.cost,
      fillup: dataObj,
    };

    fillupCost.push(fillupPoint);
    mpgPoints.push(mpgPoint);
    costPerGallonPoints.push(cpgPoint);
  }

  return { mpg: mpgPoints, cpg: costPerGallonPoints, cost: fillupCost };
}
