import axios from "axios";
const URL = process.env.REACT_APP_API_SERVER_URL;

// Gets a token to be used with the Auth0 User API
export const getGasStations = async (coords, isAuthenticated, token) => {
  if (isAuthenticated) {
    const res = await axios.get(
      `${URL}api/gasStations/${coords.lat}/${coords.lon}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  }
};
