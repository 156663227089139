import { Container } from "react-bootstrap";
import CreateTrips from "../Components/Trips/CreateTrips";
import { useContext, useState } from "react";
import {
  useDeleteTrip,
  useGetListOfTrips,
  useGetTripDetails,
} from "../hooks/useTrips";
import AppContext from "../auth/AppContext";
import { useAuth0 } from "@auth0/auth0-react";
import DashBoardContent from "../Components/Dashboard/DashBoardContent";
import { ToastContainer, toast } from "react-toastify";
import LoadingInApp from "../Loading/LoadingInApp";
import HorizontalLine from "../Components/LookAndFeel/HorizontalLine";
import TripsButtons from "../Components/Trips/TripsButtons";

const Trip = () => {
  const { user } = useAuth0();
  const { token } = useContext(AppContext);
  const [showCreateTrip, setShowCreateTrip] = useState(false);
  const [tripId, setTripId] = useState(null);
  const { data: trips } = useGetListOfTrips(token, user.sub);
  const { data: tripDetails, isLoading } = useGetTripDetails(token, tripId);
  const { mutateAsync: deleteTrip } = useDeleteTrip(token);

  const { fillups, fillStats, fillupCoords } = tripDetails || {};

  const handleTripSelection = (event) =>
    event.target.value !== "Select Trip" ? setTripId(event.target.value) : null;

  const handleTripDelete = async (event) => {
    event.preventDefault();
    toast.info("Deleting Trip...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      theme: "light",
    });
    await deleteTrip(tripId);
    setTripId(null);
    toast.dismiss();
    toast.success("Trip Deleted", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      theme: "light",
    });
  };

  return (
    <>
      <Container>
        <h3>Trips</h3>
      </Container>
      <HorizontalLine />
      <TripsButtons
        setShowCreateTrip={setShowCreateTrip}
        tripId={tripId}
        handleTripDelete={handleTripDelete}
        trips={trips}
        handleTripSelection={handleTripSelection}
      />
      <Container>
        {isLoading && tripId ? (
          <LoadingInApp />
        ) : (
          <div>
            <DashBoardContent
              fillups={fillups}
              fillStats={fillStats}
              fillupCoords={fillupCoords}
            />
          </div>
        )}
        <CreateTrips
          showCreateTrip={showCreateTrip}
          setShowCreateTrip={setShowCreateTrip}
        />
        <ToastContainer />
      </Container>
    </>
  );
};

export default Trip;
