import { Button, Container } from "react-bootstrap";
import { useContext, useEffect } from "react";
import axios from "axios";

function GasPrices() {
  return (
    <>
      <h2 className="p-3"> {" "} Coming soon... Anyone know a of a free gas price API I can leverage? Let me know, <a href="mailto:cADz.LLC@gmail.com">cADz.LLC@gmail.com</a> </h2>
    </>
  );
}

export default GasPrices;
