import { Form } from "react-bootstrap";

function Gallons({ setGallons, gallons, handleFocusOut }) {
  
  return (
    <Form.Group className="mb-2">
      <Form.Label>Gallons*</Form.Label>
      <Form.Control
        type="number"
        inputMode="decimal"
        required
        value={gallons}
        onBlur={handleFocusOut}
        onChange={(event) => {
          setGallons(event.target.value);
        }}
      />
    </Form.Group>
  );
}

export default Gallons;
