import { Card } from "react-bootstrap";
import Variables from "../../Utils/Variables";

function CostPerMileCard({ costPerMile }) {
  
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={costPerMile}>
        <Card.Header className="card-header">Cost per Mile</Card.Header>
        <Card.Body>
          <Card.Text>
          <div className="card-txt">{costPerMile.toLocaleString("en-us", Variables.NUMBER_OPTIONS)}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CostPerMileCard;
