import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import "./map.css";
import { useCallback, useEffect, useState } from "react";
import Variables from "../Utils/Variables";
import { Table } from "react-bootstrap";

const GoogleMultiPointMap = ({ markers }) => {
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // This handles showing data when you click on an item on the map
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  // We use this to put the map into state and make it available
  const onLoad = useCallback((map) => setMap(map), []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS,
  });

  // Limit points to the last 10 fill ups
  if (markers.length > 10) {
    markers = markers.slice(0, 10);
  }

  // This looks for new markers and resizes the bounds
  useEffect(() => {
    if (map) {
      map.setOptions(Variables.GOOGLE_MAP_OPTIONS);
      const bounds = new window.google.maps.LatLngBounds();
      markers?.map((marker) => {
        bounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, markers]);

  return (
    <div className="multi-point-map">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap mapContainerClassName="map-container" onLoad={onLoad}>
          {markers?.map(({ lat, lng, name, addr, date }, index) => {
            return (
              <MarkerF
                position={{ lat: lat, lng: lng }}
                clickable={true}
                title={`${date} ${name} ${addr}`}
                onClick={() =>
                  handleMarkerClick({ lat, lng, name, addr, date })
                }
                key={index}
              />
            );
          })}

          {selectedMarker && (
            <InfoWindowF
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              {selectedMarker.name ? (
                <Table bordered={false}>
                  <tbody>
                    <tr>
                      <td>Station:</td>
                      <td>{selectedMarker.name}</td>
                    </tr>
                    {selectedMarker.addr && (
                      <tr>
                        <td>Address: </td>
                        <td>{selectedMarker.addr}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Date:</td>
                      <td>{selectedMarker.date}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <Table bordered={false}>
                  <tbody>
                    <tr>
                      <td>No station info </td>
                    </tr>
                    <tr>
                      {" "}
                      <td> {selectedMarker.date}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </InfoWindowF>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default GoogleMultiPointMap;
