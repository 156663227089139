import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AppContext from "./AppContext";

// This determines if the person has appropriate role
// TODO: Work solution to handle more than admin if needed
export const GroupGuard = (props) => {
  // Get the groups that the person is a member of
  const {groups} = useContext(AppContext);

  // If they're not an admin, send themt o the home page
  if (!groups || !groups.find((role) => role.name === "admin")) {
    return <Navigate to="/" />;
  }
  return props.children;
};
