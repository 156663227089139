import { Form } from "react-bootstrap";

function MissedFill({ isMissedFill, setIsMissedFill }) {
  return (
    <Form.Group className="mb-2" controlId="formCheckbox">
      <Form.Check
        type="checkbox"
        label="Missed Last Fill"
        checked={isMissedFill}
        onChange={(event) => {
          setIsMissedFill(event.target.checked);
        }}
      />
    </Form.Group>
  );
}

export default MissedFill;
