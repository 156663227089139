import axios from "axios";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import fills from "../LoadData/import.json";


function useBulkFillup() {
  const [response, setResponse] = useState();
  const [respCode, setReponseCode] = useState();
  const { user } = useAuth0();

  // Used for bulk import. Could be expanded to a feature.
  const bulkFillup = async (token) => {
    console.log("starting bulk import...");

    fills.map((fill) => {
      if (!fill.tripDistance) {
        fill.tripDistance = 0;
      }
      if (!fill.tripMpg) {
        fill.tripMpg = 0;
      }
      fill.costPerGallon = parseFloat(fill.cost / fill.gallons);
      fill.tripMpg = parseFloat(fill.tripMpg.toFixed(2));
      fill.cost = parseFloat(fill.cost);
      fill.location = [{ name: fill.location, lat: fill.lat, lon: fill.lon }];
      fill.vehicle = [
        { vehicleId: fill.vehicleNumber, vehicle: fill.vehicleName },
      ];
      // fill.user = user.sub;
      delete fill.lat;
      delete fill.lon;
      delete fill.vehicleName;
      delete fill.vehicleNumber;
    });

    console.log(fills);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}api/bulkFillup`,
        fills,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setResponse(res.data);
      setReponseCode(res.status);
    } catch (error) {
      console.log(error);
    }
    console.log("Done with bulk import...");
  };

  return { bulkFillup };
}

export default useBulkFillup;
