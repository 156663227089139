import { Container, Form, FormGroup, Offcanvas } from "react-bootstrap";
import VehiclesDropDown from "../Vehicle/VehiclesDropDown";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Variables from "../../Utils/Variables";
import { useAuth0 } from "@auth0/auth0-react";
import AppContext from "../../auth/AppContext";
import { getVehicleFillups } from "../../API/FillupApi";
import BootstrapTable from "react-bootstrap-table-next";
import { TABLE_OPTIONS, TRIP_COLUMNS } from "../Dashboard/TableConfig";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useCreateTrip } from "../../hooks/useTrips";
import { ToastContainer, toast } from "react-toastify";
import LoadingInApp from "../../Loading/LoadingInApp";

const CreateTrips = ({ showCreateTrip, setShowCreateTrip }) => {
  const [vehicle, setVehicle] = useState(null);
  const [tripFillups, setTripFillups] = useState([]);
  const [tripName, setTripName] = useState("");
  const { user } = useAuth0();
  const { token } = useContext(AppContext);
  const { mutateAsync: createTrip, isLoading: isCreatingTrip } =
    useCreateTrip(token);

  const { data, isLoading: isLoadingFillups } = useQuery({
    queryKey: [Variables.FILLUPS, user, vehicle?.vehicleId, token],
    queryFn: () => getVehicleFillups(user, vehicle?.vehicleId, token),
    enabled: !!token && !!user && !!vehicle,
  });

  const handleRowSelect = (isSelect, row) => {
    if (isSelect) {
      setTripFillups([...tripFillups, row._id]);
    } else {
      setTripFillups(tripFillups.filter((tripId) => tripId !== row._id));
    }
  };

  const handleCreateTrip = async (event) => {
    event.preventDefault();

    toast.info("Creating Trip...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      theme: "light",
    });
    const trip = {
      desc: tripName,
      fillUpIds: tripFillups,
      user: user.sub,
      vehicleId: vehicle?.vehicleId,
    };

    await createTrip(trip);
    setShowCreateTrip(false);
    toast.dismiss();
    toast.success("Trip Created", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      theme: "light",
    });

    setVehicle(null);
    setTripName("");
    setTripFillups([]);
  };

  const handleHide = () => {
    setShowCreateTrip(false);
  };
  const { fillups } = data || {};

  return (
    <>
      <Offcanvas show={showCreateTrip} onHide={handleHide}>
        <Offcanvas.Header closeButton closeVariant={"white"}>
          <Offcanvas.Title>Create a Trip</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Form>
              <FormGroup>
                <Form.Label>Trip Details</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Trip Name"
                  onChange={(event) => setTripName(event.target.value)}
                  value={tripName}
                />
                <VehiclesDropDown setVehicleId={setVehicle} />
                {isLoadingFillups && vehicle ? (
                  <LoadingInApp />
                ) : (
                  fillups &&
                  fillups.length > 0 && (
                    <BootstrapTable
                      striped
                      keyField="_id"
                      data={fillups}
                      columns={TRIP_COLUMNS}
                      pagination={paginationFactory(TABLE_OPTIONS)}
                      selectRow={{
                        mode: "checkbox",
                        onSelect: (row, isSelect, rowIndex, e) => {
                          handleRowSelect(isSelect, row);
                        },
                      }}
                    ></BootstrapTable>
                  )
                )}
                <div className="text-end">
                  <button
                    className="btn-custom"
                    onClick={handleCreateTrip}
                    disabled={
                      tripName.length === 0 ||
                      !vehicle ||
                      isCreatingTrip ||
                      isLoadingFillups ||
                      vehicle.vehicleId === "0"
                        ? "disabled"
                        : undefined
                    }
                  >
                    Create Trip
                  </button>
                </div>
              </FormGroup>
            </Form>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer position="top-center" />
    </>
  );
};
export default CreateTrips;
