import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Custom hook for getting a token
function useToken() {
  const [token, setToken] = useState();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getToken = async () => {
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch {
        console.log("Failed to get token");
      }
    }
  };
  return { token, getToken };
}

export default useToken;
