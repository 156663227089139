import "./hamburger.css";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Logout from "./Logout";
import { useContext } from "react";
import AppContext from "../auth/AppContext";

function Hamburger() {
  const { groups = [] } = useContext(AppContext);

  return (
    <Navbar.Collapse
      id="responsive-navbar-nav"
      className="mb-2 justify-content-end"
    >
      <Nav className="mr-auto ">
        <Nav.Link eventKey={1}>
          <Link className="hamburger" to="/">
            Home
          </Link>
        </Nav.Link>
        <Nav.Link eventKey={5}>
          <Link className="hamburger" to="/dashboard">
            Dashboard
          </Link>
        </Nav.Link>
        <Nav.Link eventKey={3}>
          <Link className="hamburger" to="/fillup">
            Add Fill-up
          </Link>
        </Nav.Link>
        <Nav.Link eventKey={4}>
          <Link className="hamburger" to="/addVehicle">
            Manage Vehicles
          </Link>
        </Nav.Link>
        <Nav.Link eventKey={4}>
          <Link className="hamburger" to="/trip">
            Trips
          </Link>
        </Nav.Link>
        <Nav.Link eventKey={2}>
          <Link className="hamburger" to="/profile">
            Profile
          </Link>
        </Nav.Link>
        <Nav.Link eventKey={2}>
          <Link className="hamburger" to="/gasPrices">
            Gas Prices
          </Link>
        </Nav.Link>
        {groups?.find((group) => group.name === "admin") && (
          <Nav.Link eventKey={2}>
            <Link className="hamburger" to="/admin">
              Admin Page
            </Link>
          </Nav.Link>
        )}
        <Logout />
      </Nav>
    </Navbar.Collapse>
  );
}

export default Hamburger;
