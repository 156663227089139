import axios from "axios";
const URL = process.env.REACT_APP_API_SERVER_URL

// Let a list of vehicles
export const getVehicles = async (user, activeOnly, token) => {
  const res = await axios.get(
    `${URL}api/vehicles/user/${user.sub}/${activeOnly}/`,
    {
      timeout: 100000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await res.data;
  return data;
};

//Toggle the status of a vehicle
export const toggleVehicleStatus = async (vehicleId, vehicleStatus, token) => {
  const res = await axios.put(
    `${URL}api/toggleVehicleStatus/${vehicleId}/${vehicleStatus}`, vehicleStatus,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

// Add a vehicle
export const addVehicle = async (vehicle, token) => {
  const res = await axios.post(
    `${URL}api/addVehicle`,
    vehicle,
    {
      timeout: 100000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

// Delete a vehicle
export const deleteVehicle = async (vehicleId, token) => {
  const res = await axios.delete(
    `${URL}api/deleteVehicle/id/${vehicleId}`,
    {
      timeout: 100000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

// Get a single vehicle by ID
export const getVehicle = async (vehicleId, token) => {
  const res = await axios.get(
    `${URL}api/vehicle/${vehicleId}`,
    {
      timeout: 100000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
