import { Form } from "react-bootstrap";

function PartialFill({ isPartialFill, setIsPartialFill }) {
  return (
    <Form.Group className="mb-2" controlId="formCheckbox">
      <Form.Check
        type="checkbox"
        label="Partial Fill"
        checked={isPartialFill}
        onChange={(event) => {
          setIsPartialFill(event.target.checked);
        }}
      />
    </Form.Group>
  );
}

export default PartialFill;
