import { Card } from "react-bootstrap";
import "./Cards.css";

function TotalMilesCard({ milesTraveled }) {
  
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={milesTraveled}>
        <Card.Header className="card-header">Miles Logged</Card.Header>
        <Card.Body>
          <Card.Text>
            <div className="card-txt">{milesTraveled.toLocaleString("en-us")}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TotalMilesCard;
