import { Card } from "react-bootstrap";
import "./Cards.css";

function PerGallonCard({ gallon }) {
  
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={gallon}>
        <Card.Header className="card-header">Gallon Avg</Card.Header>
        <Card.Body>
          <Card.Text>
          <div className="card-txt">${parseFloat(gallon).toFixed(2)}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PerGallonCard;
