import "./hamburger.css";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/fillup",
      },
    });
  };

  return (
    <button className="btn-custom" onClick={handleLogin}>
      Log in
    </button>
  );
}

export default Login;
