class Variables {
  // Used for React-Query
  static VEHICLES = "vehicles";
  static VEHICLE = "vehicle";
  static FILLUPS = "fillups";
  static STAT_TOTALS = "statTotals";
  static TRIPS = "trips";
  static TRIP_DETAILS = "tripDetails";

  // formats mm/dd/yyyy
  static DATE_OPTIONS = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  };

  // Formats numbers with commas and 2 decimals
  static NUMBER_OPTIONS = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  static GOOGLE_MAP_OPTIONS = {
    draggableCursor: false,
    mapTypeControl: false,
    streetViewControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };
}

export default Variables;
