import { Button } from "react-bootstrap";

function Buttons({ enableSubmit, handleAddFill, handleClear }) {
  return (
    <div className="text-end">
      <button
        className="btn-custom"
        disabled={enableSubmit ? "" : "disabled"}
        onClick={handleAddFill}
      >
        Add Fill-up
      </button>{" "}
      <button className="btn-custom" onClick={handleClear}>
        Clear
      </button>
    </div>
  );
}

export default Buttons;
