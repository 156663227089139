import { Container } from "react-bootstrap";
import DashboardButtons from "../Components/Dashboard/DashboardButtons";
import { useState, useContext } from "react";

import LoadingInApp from "../Loading/LoadingInApp";
import { getVehicleFillups } from "../API/FillupApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import HorizontalLine from "../Components/LookAndFeel/HorizontalLine";
import Variables from "../Utils/Variables";
import AppContext from "../auth/AppContext";
import DashBoardContent from "../Components/Dashboard/DashBoardContent";

function Dashboard() {
  const [vehicle, setVehicle] = useState("");

  const { user } = useAuth0();
  const { token } = useContext(AppContext);
  // Get fill ups for a vehicle selected
  const { data, isLoading } = useQuery({
    queryKey: [Variables.FILLUPS, user, vehicle?.vehicleId, token],
    queryFn: () => getVehicleFillups(user, vehicle?.vehicleId, token),
    enabled: !!token && !!user,
  });

  const { fillups, fillStats, fillupCoords } = data || {};

  return (
    <>
      <Container>
        <h3>Dashboard</h3>
      </Container>
      <HorizontalLine />
      <DashboardButtons setVehicleId={setVehicle} />

      {isLoading ? (
        <LoadingInApp />
      ) : (
        vehicle && (
          <DashBoardContent
            fillups={fillups}
            fillStats={fillStats}
            fillupCoords={fillupCoords}
          />
        )
      )}
    </>
  );
}

export default Dashboard;
