import { Table } from "react-bootstrap";
import { useState } from "react";
import { getVehicles } from "../../API/VehiclesApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import LoadingInApp from "../../Loading/LoadingInApp";
import Variables from "../../Utils/Variables";
import VehicleOffCanvas from "./VehicleOffCanvas";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";

function ExhistingVehicles() {
  const { user } = useAuth0();
  const { token } = useContext(AppContext);
  const [displayVehicleDetails, setDisplayVehicleDetails] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState("");

  // Get vehicles - the undefined is a hack right now. Need to redesign API - undefined basically means give me everything
  const { data: vehicles, isLoading } = useQuery({
    queryKey: [Variables.VEHICLES, user, undefined, token],
    queryFn: () => getVehicles(user, undefined, token),
    enabled: !!token,
  });

  // Handle clicking the row
  const handleRowClick = (event) => {
    setDisplayVehicleDetails(true);
    setSelectedVehicleId(event.target.id);
  };

  return (
    <div className="pb-2">
      {isLoading ? (
        <LoadingInApp />
      ) : (
        <>
          {vehicles?.length > 0 && (
            <>
              <h4 className="pt-2">All Vehicles - Click to Update</h4>

              <Table striped bordered hover>
                <thead key="thead">
                  <th>Year</th>
                  <th>Vehicle</th>
                  <th>Desc</th>
                  <th>Active</th>
                </thead>
                <tbody>
                  {!isLoading &&
                    vehicles?.map((vehicle) => {
                      return (
                        <tr key={vehicle._id} onClick={handleRowClick}>
                          <td id={vehicle._id}>{vehicle.year}</td>
                          <td id={vehicle._id}>
                            {vehicle.make} {vehicle.model}
                          </td>
                          <td id={vehicle._id}>{vehicle.desc}</td>
                          <td id={vehicle._id}>
                            {vehicle.active ? "Yes" : "No"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
      {selectedVehicleId?.length > 0 && (
        <VehicleOffCanvas
          displayVehicleDetails={displayVehicleDetails}
          setDisplayVehicleDetails={setDisplayVehicleDetails}
          selectedVehicleId={selectedVehicleId}
        />
      )}
    </div>
  );
}

export default ExhistingVehicles;
