import { Form } from "react-bootstrap";

function Towing({ towing, setTowing }) {
  return (
    <Form.Group className="mb-2" controlId="formCheckbox">
      <Form.Check
        type="checkbox"
        label="Towing"
        checked={towing}
        onChange={(event) => {
          setTowing(event.target.checked);
        }}
      />
    </Form.Group>
  );
}

export default Towing;
