import "./header.css";
import Container from "react-bootstrap/Container";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const { isAuthenticated, user } = useAuth0();
  return (
    <div className="header header-background">
      <Container className="text-end header ">
        {isAuthenticated ? (
          <>
            Welcome,{" "}
            <span className="fw-bold"> {user.name.split(" ")[0]} </span>
            <img
              className="profile-pic pt-1"
              src={user.picture}
              alt="Profile pic"
            />
          </>
        ) : (
          "Welcome!"
        )}
      </Container>
    </div>
  );
}

export default Header;
