import { Form, Table } from "react-bootstrap";
import Variables from "../../Utils/Variables";

function LastFillup({ fillup }) {

  return (
    <Form.Group className="mb-3" controlId="formDropDown">
      <Table className="text-center">
        <tr>
        <td>Miles: </td>
        <td>Cost: </td>
        <td>
          Date:
        </td>
      </tr>
      <tr>
        <td>{(fillup.miles).toLocaleString("en-us")}</td>
        <td>${(fillup.cost).toFixed(2)}</td>
        <td>
          {new Date(fillup.createdOn).toLocaleString("us-en", Variables.DATE_OPTIONS)}
        </td>
      </tr>
      </Table>
    </Form.Group>
  );
}

export default LastFillup;
