import { Form } from "react-bootstrap";

function Note({ setNote, note, handleFocusOut }) {
  return (
    <Form.Group className="mb-2 mt-3" controlId="exampleForm.ControlTextarea1">
      <h4>Notes</h4>
      <Form.Control
        value={note}
        as="textarea"
        rows={4}
        onChange={(event) => {
          setNote(event.target.value);
        }}
      />
    </Form.Group>
  );
}

export default Note;
