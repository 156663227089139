import { Form } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

function LocationDropDown({ gasStations, setLocation }) {
  const { user } = useAuth0();

  // On change from first selected station, set the new gas station
  const onChangeLocation = (event) => {
    setLocation({ ...gasStations[event.target.selectedIndex] });
  };

  // Sets the location using the first one in the list. This prevents users from having to select location when
  useEffect(() => {
    if (gasStations) {
      setLocation({ ...gasStations[0] });
    }
  }, [gasStations]);

  return (
    <Form.Group controlId="formDropDown" className="pt-1">
      <Form.Select
        style={{ maxWidth: "500px" }}
        aria-label="Vehicles"
        required
        onChange={onChangeLocation}
      >
        {gasStations && gasStations.length > 0 ? (
          gasStations.map((gasStation) => {
            return (
              <option key={gasStation.name} value={gasStation.name}>
                {gasStation.name}
              </option>
            );
          })
        ) : (
          <option key={gasStations?.name} value={gasStations?.name}>
            {gasStations?.name}
          </option>
        )}
      </Form.Select>
    </Form.Group>
  );
}

export default LocationDropDown;
