import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Loading from "../Loading/Loading";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="spinner">
        <Loading />
      </div>
    ),
  });
  
  return <Component />;
};