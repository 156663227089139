import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { useLocation } from "react-router-dom";

export const CallbackPage = () => {
  const location = useLocation();
  const [errorDescription, setErrorDescription] = useState();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    const errorDescription = params.get("error_description");

    if (error && errorDescription) {
      setErrorDescription(errorDescription);
    }
  }, [location.search]);
  return <div>{errorDescription ? <>{errorDescription}</> : <Header />}</div>;
};
