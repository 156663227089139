import { Table } from "react-bootstrap";
import { getVehicles } from "../../API/VehiclesApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import LoadingInApp from "../../Loading/LoadingInApp";
import Variables from "../../Utils/Variables";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";

function ExhistingVehicles() {
  const { user } = useAuth0();
  const { token } = useContext(AppContext);

  // Get vehicles
  const { data: vehicles, isLoading } = useQuery({
    queryKey: [Variables.VEHICLES, user, token],
    queryFn: () => getVehicles(user, true, token),
    enabled: !!token,
  });

  return (
    <div className="pb-2">
      <h4 className="pt-2">Active Vehicles</h4>
      {isLoading ? (
        <LoadingInApp />
      ) : (
        <>
          <Table striped bordered hover>
            <thead key="thead">
              <th>Year</th>
              <th>Make</th>
              <th>Model</th>
            </thead>
            <tbody>
              {!isLoading &&
                vehicles?.map((vehicle) => {
                  return (
                    <tr key={vehicle._id}>
                      <td id={vehicle._id}>{vehicle.year}</td>
                      <td id={vehicle._id}>{vehicle.make}</td>
                      <td id={vehicle._id}>{vehicle.model}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

export default ExhistingVehicles;
