import "./map.css";

import * as React from "react";
import { useState, useEffect, useRef } from "react";

import "@tomtom-international/web-sdk-maps/dist/maps.css";
import * as tt from "@tomtom-international/web-sdk-maps";

function Map({ lat, lon }) {
  const mapElement = useRef();
  const [mapZoom, setMapZoom] = useState(14);
  const [map, setMap] = useState({});

  useEffect(() => {
    let map = tt.map({
      key: process.env.REACT_APP_TOMTOM,
      container: mapElement.current,
      center: [lon, lat],
      zoom: mapZoom,
      scrollZoom: false,
      dragPan: false,
    });
    setMap(map);

    let marker = new tt.Marker({
      draggable: false, // Set whether the marker is draggable or not
      color: "red", // Set the color of the marker
    });

    // Set the marker position based on the latitude and longitude values
    marker.setLngLat([lon, lat]);

    // Add the marker to the map
    marker.addTo(map);
  }, [lat, lon]);

  return (
    <div>
      <div ref={mapElement} className="mapDiv mt-3 border rounded border"></div>
    </div>
  );
}

export default Map;
