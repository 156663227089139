import KeepAlive from "../API/KeepAlive";
import HomeContent from "../Components/Home/HomeContent";
import WarmUp from "../Loading/WarmUp";

function Home() {
  // Keeps the server alive
  const keepAlive = KeepAlive();

  return (
    <>
      {keepAlive.isLoading && <WarmUp />}
      <h1> Welcome to MotoMileage</h1>
      <br></br>
      <HomeContent />
    </>
  );
}

export default Home;
