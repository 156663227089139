import axios from "axios";
const URL = process.env.REACT_APP_API_SERVER_URL;

// Get user groups - migrate to API server
export const getGroups = async (user, isAuthenticated, token) => {
  if (isAuthenticated) {
    const res = await axios.get(`${URL}api/getGroups/${user.sub}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
};

// Set a default group
export const setDefaultGroup = async (user, isAuthenticated, token) => {
  if (isAuthenticated) {
    const res = await axios.post(`${URL}api/setDefaultGroup/${user.sub}`, "", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }
};
