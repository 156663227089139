import { Card } from "react-bootstrap";

function TotalMilesLogged({ totalMilesLogged }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2 custom-card-background" key={totalMilesLogged}>
        <Card.Body>
          <Card.Text>
            <div className="stats-txt">
              {parseInt(totalMilesLogged.toFixed(0)).toLocaleString()} miles
              logged
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TotalMilesLogged;
