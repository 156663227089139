import { useState } from "react";
import { Form } from "react-bootstrap";

function Mileage({ setMiles, miles, distanceTraveled, handleFocusOut }) {
  const [isMileageLessThanLastFillup, setIsMileageLessThanLastFillup] =
    useState(false);

  // If the distance traveled is less than zero, show an alert
  const handleDistanceTraveled = () => {
    if (distanceTraveled < 0) {
      setIsMileageLessThanLastFillup(true);
    } else {
      setIsMileageLessThanLastFillup(false);
    }
  };
  return (
    <Form.Group className="mb-2">
      <Form.Label>Mileage*</Form.Label>
      <Form.Control
        type="number"
        inputMode="decimal"
        id="miles"
        required
        value={miles}
        onChange={(event) => {
          setMiles(event.target.value);
          handleFocusOut();
        }}
        onBlur={() => {
          handleFocusOut();
          handleDistanceTraveled();
        }}
      />
      {isMileageLessThanLastFillup && <span className="text-danger">Mileage is less than last fill up</span>}
    </Form.Group>
  );
}

export default Mileage;
