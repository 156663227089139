import { Card, Table } from "react-bootstrap";
import "./FillupCard.css";
import Variables from "../../Utils/Variables";

function FillupCard({ fillup }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2 min-width" key={fillup.createdOn}>
        <Card.Header className="custom-card-background">
          {fillup.vehicle[0].vehicle}
        </Card.Header>
        <Card.Body>
          <Card.Text>
            Mileage: {fillup.miles.toLocaleString()} <br></br>Gallons:{" "}
            {fillup.gallons} <br></br>Cost: ${fillup.cost.toFixed(2)} <br></br>
            {fillup.partialFill && "Partial fill-up"}
            {fillup.partialFill && <br />}
            {fillup.location[0].name} <br></br>
            {fillup.towing && (
              <>
                Towing <br />
              </>
            )}
            {!fillup.partialFill && fillup.tripMpg !== undefined
              ? `${fillup.tripMpg} mpg over ${fillup.tripDistance} miles`
              : `${fillup.tripDistance} miles traveled`}
            <br></br>
            {fillup.note && `Note: ${fillup.note}`}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="custom-card-background">
          {new Date(fillup.createdOn).toLocaleString(
            "us-en",
            Variables.DATE_OPTIONS
          )}
        </Card.Footer>
      </Card>
    </div>
  );
}

export default FillupCard;
