import { Card } from "react-bootstrap";
import Variables from "../../Utils/Variables";

function CostLast30Days({ costLast30Days }) {
  return (
    <div className="col mx-auto">
      <Card className="mb-2" key={costLast30Days}>
        <Card.Header className="card-header">Cost last 30 days</Card.Header>
        <Card.Body>
          <Card.Text>
            <div className="card-txt">
              $
              {costLast30Days.toLocaleString("en-us", Variables.NUMBER_OPTIONS)}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CostLast30Days;
