import axios from "axios";
const URL = process.env.REACT_APP_API_SERVER_URL

// Add a fill up
export const addFillup = async (fillup, token) => {
  const res = await axios.post(
    `${URL}api/fillup`,
    fillup,
    {
      timeout: 50000, // Set a timeout of 50 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// Get the specified number of fillups for a user AND vehicle
export const getNumberOfFillups = async (user, vehicleId, count, token) => {
  const res = await axios.get(
    `${URL}api/fillups/amount/${user.sub}/${vehicleId}/${count}`,
    {
      timeout: 50000, // Set a timeout of 50 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

// Get all the fill ups for a specific vehicle and user - used on dashboard
export const getVehicleFillups = async (user, vehicleId, token) => {
  console.log("starting...");
  const startTime = Date.now();
  const res = await axios.get(
    `${URL}api/fillups/all/${user.sub}/${vehicleId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  console.log("Finishing...");
  const endTime = Date.now();
  console.log(
    `It took ${(endTime - startTime) / 1000} seconds get fillup data`
  );

  return res.data;
};

// Get all of the fillups for a user, possibly limited to a specific count
export const getFillups = async (user, count, token) => {
  try {
    const res = await axios.get(
      `${URL}api/fillups/${user.sub}/${count}`,
      {
        timeout: 50000, // Set a timeout of 50 seconds
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Deletes a fillup
export const deleteFillup = async (fillupId, token) => {
  try {
    const res = await axios.delete(
      `${URL}api/fillup/delete/${fillupId}`,
      {
        timeout: 50000, // Set a timeout of 50 seconds
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Deletes all fill ups for a user
export const deleteAllFillupsForUser = async (user, token) => {
  try {
    const res = await axios.delete(
      `${URL}api/fillup/deleteAll/${user.sub}`,
      {
        timeout: 50000, // Set a timeout of 50 seconds
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Deletes all fill ups for a vehicle
export const deleteAllFillupsForVehicle = async (vehicleId, token) => {
  try {
    const res = await axios.delete(
      `${URL}api/fillup/deleteAllFillupsByVehicleId/${vehicleId}`,
      {
        timeout: 50000, // Set a timeout of 50 seconds
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
