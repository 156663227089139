import useBrowserLocation from "./../../hooks/useBrowserLocation";
import LocationDropDown from "./LocationDropDown";
import { getGasStations } from "../../API/TomTomApi";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import AppContext from "../../auth/AppContext";
import LoadingInApp from "../../Loading/LoadingInApp";

function Location({ setLocation }) {
  const { isAuthenticated } = useAuth0();
  const { coords, getBrowserLocation } = useBrowserLocation();
  const { token } = useContext(AppContext);

  // Get the location from the browser
  useEffect(() => {
    getBrowserLocation();
  }, []);

  // Get gas stations
  const { data: gasStations, isLoading } = useQuery({
    queryKey: ["gasStations", coords, isAuthenticated, token],
    queryFn: () => getGasStations(coords, isAuthenticated, token),
    enabled: !!coords,
  });

  return (
    <div>
      <h4 className="mt-3">Location</h4>
      {isLoading ? (
        <LoadingInApp />
      ) : (
        <LocationDropDown gasStations={gasStations} setLocation={setLocation} />
      )}
    </div>
  );
}

export default Location;
