import { Container, Navbar } from "react-bootstrap";
import TripsDropDown from "./TripsDropDown";

function TripsButtons({
  setShowCreateTrip,
  trips,
  handleTripSelection,
  handleTripDelete,
  tripId,
}) {
  return (
    <Navbar className="mb-3 pb-0 pt-0 text-nowrap">
      <Container className="p-1">
        <div className="justify-content-start m-1">
          <TripsDropDown
            trips={trips}
            handleTripSelection={handleTripSelection}
          />
        </div>
        <div className="justify-content-end">
          <button
            onClick={() => setShowCreateTrip(true)}
            className="btn-custom"
          >
            Create Trip
          </button>{" "}
          {tripId && (
            <button onClick={handleTripDelete} className="btn-custom ">
              Delete Trip
            </button>
          )}
        </div>
      </Container>
    </Navbar>
  );
}

export default TripsButtons;
