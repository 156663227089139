import { Form, InputGroup } from "react-bootstrap";

function TotalCost({ formatTotalCost, totalCost, handleFocusOut }) {
  return (
    <Form.Group className="mb-2">
      <Form.Label>Total Cost*</Form.Label>
      <InputGroup>
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          type="number"
          aria-label="Total Cost"
          required
          inputMode="decimal"
          value={totalCost}
          onBlur={handleFocusOut}
          onChange={(event) => {
            formatTotalCost(event.target.value);
          }}
        />
      </InputGroup>
    </Form.Group>
  );
}

export default TotalCost;
