// Figures out if we have enough information to attempt to autofill data
const isAutoFillPossible = (price, gallons, totalCost) => {
  if ((price && gallons) || (price && totalCost) || (gallons && totalCost)) {
    return true;
  } else {
    return false;
  }
};

export { isAutoFillPossible };
