import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import VehiclesDropDown from "../Vehicle/VehiclesDropDown";
import FillupVehicleButtons from "../LookAndFeel/FillUpVehicleButtons";

function DashboardButtons({ setVehicleId }) {
  return (
    <Navbar className="mb-3 pb-0 pt-0 text-nowrap">
      <Container className="p-1">
        <VehiclesDropDown setVehicleId={setVehicleId} allOption={true} />
        <FillupVehicleButtons />
      </Container>
    </Navbar>
  );
}

export default DashboardButtons;
