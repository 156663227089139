import "./header.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Hamburger from "./Hamburger";
import { Link } from "react-router-dom";
import Signup from "./Signup";
import Login from "./Login";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const { isAuthenticated } = useAuth0();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="mb-3 pb-1 pt-0 header header-background"
      sticky="top"
    >
      <Container className="text-nowrap">
        <img
          className="header-img mb-2"
          src="images/gas-icon-6148.png"
          alt="Gas gauge icon"
        />
        <Link className="header text-nowrap d-flex text-decoration-none" to="/">
          <h1>MotoMileage</h1>
        </Link>

        {isAuthenticated && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Hamburger />
          </>
        )}

        {!isAuthenticated && (
          <div className="justify-content-end">
            <Login /> <Signup />
          </div>
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
