import HomeCards from "./HomeCards";
import "./home.css";

function Home() {
  return (
    <div>
      <div class="pb-3">
        <h1 class="display-5 text-right">
          READY TO GO FOR A DRIVE? LETS ROLL....
        </h1>
      </div>
      <div class="pb-2">
        <p class="display-6">
          MotoMileage helps you track your vehicles fuel economy, spend, and
          fueling locations. Tracking your usage enables you to make informed
          decisions about your driving habits, where you get gas, the health of
          your vehicle and budget.
        </p>
        <HomeCards />
        <p class="display-6">
          This site was{" "}
          <b>created for educational and personal use. It is free to use </b>{" "}
          but I cannot guarntee availability or that it is free from defects.
        </p>
      </div>

      <div class="row mt-4 mb-5 text-center flex-wrap">
        <div class="p-2 col-md-4">
          <img class="pb-3" src="images/gas.png" alt="Gas gauge icon" />
          <p class="display-7">Learn your real gas mileage and consumption</p>
        </div>
        <div class="p-2 col-md-4">
          <img class="pb-3" src="images/location.png" alt="Location icon" />
          <p class="display-7">Know when and where you're filling up</p>
        </div>
        <div class="p-2 col-md-4">
          <img class="pb-3" src="images/graph.png" alt="Graph icon" />
          <p class="display-7">Understand your cost and budget accordingly</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
