import { useState } from "react";

import PerGallonCard from "./PerGallonCard";
import TotalCostCard from "./TotalCostCard";
import MpgChart from "./MpgChart";
import TotalMilesCard from "./TotalMilesCard";
import FillupCountCard from "././FillupCountCard";

import FillupTable from "./FillupTable";

import CostPerMileCard from "./CostPerMileCard";

import BestMpg from "./BestMpg";

import MilesLast30Days from "./MilesLast30Days";
import CostLast30Days from "./CostLast30Days";
import MpgCard from "./MpgCard";
import HorizontalLine from "../LookAndFeel/HorizontalLine";
import GoogleMultiPointMap from "../../Map/GoogleMultiPointMap";

function DashBoardContent({ fillups, fillStats, fillupCoords }) {
  const [showFillupDetails, setShowFillupDetails] = useState(false);
  return (
    <>
      {fillups && (
        <>
          <div className="row mb-3" key="statsRow">
            {fillStats.averageMpg > 0 && <MpgCard mpg={fillStats.averageMpg} />}
            {fillStats.bestMpg > 0 && <BestMpg bestMpg={fillStats.bestMpg} />}
            {fillStats.averageGallonCost > 0 && (
              <PerGallonCard gallon={fillStats.averageGallonCost} />
            )}
            {fillStats.totalCost > 0 && (
              <TotalCostCard totalCost={fillStats.totalCost} />
            )}
            <FillupCountCard count={fillStats.fillupCount} />
            {fillStats.milesTraveled > 0 && (
              <TotalMilesCard milesTraveled={fillStats.milesTraveled} />
            )}
            {fillStats.costPerMile > 0 && isFinite(fillStats.costPerMile) && (
              <CostPerMileCard costPerMile={fillStats.costPerMile} />
            )}
            {fillStats.milesLast30Days > 0 && (
              <MilesLast30Days milesLast30Days={fillStats.milesLast30Days} />
            )}
            {fillStats.costLast30Days > 0 && (
              <CostLast30Days costLast30Days={fillStats.costLast30Days} />
            )}
          </div>
          {fillups?.length > 0 && (
            <MpgChart
              fillups={fillups}
              setShowFillupDetails={setShowFillupDetails}
              showFillupDetails={showFillupDetails}
            />
          )}
        </>
      )}

      {fillups?.length > 0 && (
        <div className="pt-3">
          <FillupTable
            fillups={fillups}
            setShowFillupDetails={setShowFillupDetails}
          />
        </div>
      )}

      {fillupCoords?.length > 0 && (
        <>
          <HorizontalLine />
          <h4 className="text-center">Location - Last 10 fillups</h4>
          <GoogleMultiPointMap markers={fillupCoords} />
        </>
      )}
    </>
  );
}

export default DashBoardContent;
