import { Form } from "react-bootstrap";

function PartOfTrip({ isPartOfTrip, setIsPartOfTrip }) {
  return (
    <Form.Group className="mb-2" controlId="formCheckbox">
      <Form.Check
        type="checkbox"
        label="Add to a Trip"
        checked={isPartOfTrip}
        onChange={(event) => {
          setIsPartOfTrip(event.target.checked);
        }}
      />
    </Form.Group>
  );
}

export default PartOfTrip;
