import TotalMilesLogged from "./TotalMilesLogged";
import TotalGallonsLogged from "./TotalGallonsLogged";
import TotalFillupsLogged from "./TotalFillupsLogged";
import { useQuery } from "@tanstack/react-query";
import Variables from "../../Utils/Variables";
import { getStatTotals } from "../../API/StatsApi";

function HomeCards() {
  // Get the last fill up
  const { data: stats, isLoading } = useQuery({
    queryKey: [Variables.STAT_TOTALS],
    queryFn: () => getStatTotals(),
    refetchInterval: Infinity,
  });

  return (
    <>
      {stats?.length > 0 && (
        <div className="pb-4 pt-3">
          <div className="row">
            <TotalMilesLogged totalMilesLogged={stats[0].totalMiles} />
            <TotalGallonsLogged totalGallonsLogged={stats[0].totalGallons} />
            <TotalFillupsLogged totalFillupsLogged={stats[0].totalFillups} />
          </div>
        </div>
      )}
    </>
  );
}

export default HomeCards;
