import { Form, InputGroup } from "react-bootstrap";
const threeDecimalPattern = /^\d+\.\d{3}$/;

function Price({ setPrice, price, handleFocusOut }) {
  // This formats the price field to be 3 decimals and include 9 if not included
  const handleFormat = (event) => {
    let value = parseFloat(event.target.value);
    if (!threeDecimalPattern.test(value)) {
      value += 0.009;
      value = value.toFixed(3);
      setPrice(value);
    }
    handleFocusOut();
  };
  return (
    <Form.Group className="mb-2">
      <Form.Label>Price per Gallon*</Form.Label>
      <InputGroup>
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          type="number"
          aria-label="Price per Gallon"
          inputMode="decimal"
          required
          value={price}
          onBlur={handleFormat}
          onChange={(event) => {
            setPrice(event.target.value);
          }}
        />
      </InputGroup>
    </Form.Group>
  );
}

export default Price;
