import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import FillupOffCanvas from "./FillupOffCanvas";
import HorizontalLine from "../LookAndFeel/HorizontalLine";
import { DEFAULT_COLUMNS, TABLE_OPTIONS } from "./TableConfig";

function FillupTable({ fillups }) {
  const [showFillupDetails, setShowFillupDetails] = useState(false);
  const [rowData, setRowData] = useState("");

  // Handle clicking a row
  const rowEvents = {
    onClick: (e, row, index) => {
      setRowData(row);
      setShowFillupDetails(true);
    },
  };

  return (
    <>
      <HorizontalLine />
      <h4 className="text-center">Fillups - click for details</h4>
      <BootstrapTable
        striped
        keyField="_id"
        data={fillups}
        columns={DEFAULT_COLUMNS}
        pagination={paginationFactory(TABLE_OPTIONS)}
        rowEvents={rowEvents}
      ></BootstrapTable>
      {rowData && (
        <FillupOffCanvas
          showFillupDetails={showFillupDetails}
          setShowFillupDetails={setShowFillupDetails}
          rowData={rowData}
        />
      )}
    </>
  );
}

export default FillupTable;
