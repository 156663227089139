import "./spinner.css";

function WarmUp() {
  return (
    <div className="pb-3 text-center">
      <strong>Warming up the car... </strong>
      <div className="spinner-border text-danger " role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default WarmUp;
